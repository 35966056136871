// CSS
import styles from "./TextArrowButton.module.scss";

// Assets
import { ReactComponent as ArrowRightIcon } from "src/assets/icon/arrowRightIcon.svg";

type Props = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  text: string;
};

function TextArrowButton({ onClick, text }: Props) {
  return (
    <div className={styles.TextArrowButton} onClick={onClick}>
      <span>{text}</span>
      <div className={styles.icon}>
        <ArrowRightIcon />
      </div>
    </div>
  );
}

export default TextArrowButton;
