const HistoryEduIcon = ({ color = "#323232" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
  >
    <path
      d="M7.99994 0V1.38C7.16994 1.05 6.27994 0.88 5.38994 0.88C3.59994 0.88 1.80994 1.56 0.439941 2.93L3.76994 6.26H4.87994V7.37C5.73994 8.23 6.85994 8.68 7.98994 8.73V11H4.99994V14C4.99994 15.1 5.89994 16 6.99994 16H16.9999C18.6599 16 19.9999 14.66 19.9999 13V0H7.99994ZM6.88994 6.41V4.26H4.60994L3.56994 3.22C4.13994 3 4.75994 2.88 5.38994 2.88C6.72994 2.88 7.97994 3.4 8.92994 4.34L10.3399 5.75L10.1399 5.95C9.62994 6.46 8.94994 6.75 8.21994 6.75C7.74994 6.75 7.28994 6.63 6.88994 6.41ZM17.9999 13C17.9999 13.55 17.5499 14 16.9999 14C16.4499 14 15.9999 13.55 15.9999 13V11H9.99994V8.41C10.5699 8.18 11.0999 7.84 11.5599 7.38L11.7599 7.18L14.5899 10H15.9999V8.59L9.99994 2.62V2H17.9999V13Z"
      fill={color}
    />
  </svg>
);

export default HistoryEduIcon;
