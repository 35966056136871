import styles from "./CookieToast.module.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicButton from "src/components/atoms/BasicButton/BasicButton";
import CookieManageForm from "./CookieManageForm/CookieManageForm";
import { setCookie } from "src/utils/cookie";

type Choices = {
  Essential: boolean;
  Performance: boolean;
  Functional: boolean;
  Advertising: boolean;
};

type Props = {
  closeCookieToast: () => void;
};

export default function CookieToast({ closeCookieToast }: Props) {
  const navigate = useNavigate();
  const [isManageOpen, setIsManageOpen] = useState(false);

  const onSaveCookie = (choices: Choices) => {
    setCookie("cookieChoices", choices, { path: "/" });
    closeCookieToast();
  };

  const onClickAcceptAll = () => {
    onSaveCookie({
      Essential: true,
      Performance: true,
      Functional: true,
      Advertising: true,
    });
  };

  const toggleForm = () => {
    setIsManageOpen((prev) => !prev);
  };

  return (
    <div
      className={`${styles.container} ${isManageOpen && styles.manage_form}`}
    >
      {isManageOpen ? (
        <CookieManageForm toggleForm={toggleForm} onSaveCookie={onSaveCookie} />
      ) : (
        <>
          <div className={styles.title}>We Value Your Privacy</div>
          <div className={styles.content}>
            We use cookies to make your interactions with our website more
            meaningful. They help us better understand how our websites are
            used, so we can tailor content for you. For more information about
            the different cookies we are using, click on more information. You
            can manage this sharing by selecting the “Manage Cookies” link.
            <span
              className={styles.info_button}
              onClick={() =>
                navigate("/legal/cookies", {
                  state: { location: "cookielist" },
                })
              }
            >
              more information
            </span>
          </div>

          <div className={styles.button_container}>
            <BasicButton
              content={<span>Manage Cookies</span>}
              level="secondary"
              type="outline"
              onClick={toggleForm}
            />

            <BasicButton
              content={<span>Accept All</span>}
              onClick={onClickAcceptAll}
            />
          </div>
        </>
      )}
    </div>
  );
}
