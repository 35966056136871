import styles from "./Sitemap.module.scss";
import { useNavigate } from "react-router-dom";

// Assets
import { ReactComponent as Logo } from "src/assets/logo/BI_OpenFrame Refactor_w07.svg";

const sitemapList = [
  {
    category: "OpenFrame Refactor",
    subCategory: ["OF Analyzer", "OF Dataset Manager", "OF Migrator"],
  },
  {
    category: "Documentation",
    subCategory: [
      "Getting Started",
      "OF Analyzer/Migrator",
      "OF Dataset Manager",
    ],
  },
  {
    category: "Support Plan",
    subCategory: ["Basic Support", "Advanced Support"],
  },
  {
    category: "Training & Certification",
    subCategory: ["Beginner Course", "Intermediate Course", "FAQ"],
  },
  {
    category: "About Us",
    subCategory: ["Report", "Partners", "Other Products"],
  },
];

type SitemapListItems = (typeof sitemapList)[number];
type SubCatItems = string[number];

export default function Sitemap() {
  const navigate = useNavigate();

  const handleCategoryClick = (category: string): void => {
    console.log(category);
    if (category === "OpenFrame Refactor") {
      navigate("/products");
    } else if (category === "Support Plan") {
      navigate("/support_plan");
    } else if (category === "Training & Certification") {
      window.open("https://learn.tmaxsoft.com/");
    } else if (category === "About Us") {
      window.open("https://www.tmaxsoft.com/en/main/");
    } else {
      // do nothing
    }
  };

  const links = [
    { title: "Privacy", onclick: () => navigate("/privacypolicy") },
    {
      title: "Terms and Conditions",
      onclick: () => navigate("/termsandconditions"),
    },
    { title: "Cookie Policy", onclick: () => navigate("/legal/cookies") },
    { title: "OSS Notice", onclick: () => window.open("/license.html") },
  ];

  return (
    <div className={styles.Sitemap}>
      <div className={styles.sitemap_container}>
        <div className={styles.left_container}>
          <Logo className={styles.logo} />
        </div>

        {/* right continaer */}
        <div className={styles.right_container}>
          {sitemapList.map((sitemap: SitemapListItems) => {
            return (
              <div
                id={sitemap.category}
                className={styles.sitemap_box}
                key={sitemap.category}
              >
                <span
                  className={styles.category}
                  onClick={() => handleCategoryClick(sitemap.category)}
                >
                  {sitemap.category}
                </span>
                {sitemap.subCategory.map((subCat: SubCatItems) => {
                  return (
                    <span className={styles.subCategory} key={subCat}>
                      {subCat}
                    </span>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.bottom_text_container}>
        {links.map((item) => (
          <span
            key={item.title}
            className={styles.bottom_text_link}
            onClick={item.onclick}
          >
            {item.title}
          </span>
        ))}

        <span className={styles.bottom_text}>
          © 2023 TmaxSoft. All Rights Reserved.
        </span>
      </div>
    </div>
  );
}
