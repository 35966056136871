import { MouseEventHandler } from "react";
import styles from "./Button.module.scss";

type ButtonProps = {
  title: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  textColor?: string;
  fontSize?: string;
  borderRadius?: string;
  otherStyle?: object;
  onClick?: MouseEventHandler<HTMLDivElement>;
  // [other: string]: unknown;
};

export default function Button({
  title,
  width = "92px",
  height = "36px",
  backgroundColor = "#7244FB",
  textColor = "#ffffff",
  fontSize = "13px",
  borderRadius = "4px",
  otherStyle,
  onClick,
}: ButtonProps) {
  return (
    <div
      className={styles.Button}
      style={{
        width: width,
        height: height,
        background: backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: borderRadius,
        cursor: "pointer",
        ...otherStyle,
      }}
      onClick={onClick}
    >
      <span
        style={{
          color: textColor,
          fontSize: fontSize,
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          letterSpacing: "-0.052px",
        }}
      >
        {title}
      </span>
    </div>
  );
}
