import styles from "./Navbar.module.scss";

// Utils
import useDetectClose from "src/utils/useDetectClose";
import { useNavigate } from "react-router-dom";

// Assets
import RecieptLongIcon from "src/assets/reciept_long";
import BusinessIcon from "src/assets/business";
import HistoryEduIcon from "src/assets/history_edu";
import RefactorLogo from "src/assets/logo/BI_OpenFrame Refactor_b02.svg";
// import GettingStartedPdf from "src/assets/resource/OpenFrame Suite Getting Started v1.0_en.pdf";

// Hooks
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Components
import MenuPopover from "src/components/molecules/MenuPopover/MenuPopover";
import Button from "src/components/atoms/Button/Button";

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [hoveredComponent, setHoveredComponent] = useState("");

  useEffect(() => {
    setHoveredComponent("");
  }, [location]);

  const onChangeHover = (name: string, onHover: Function) => {
    setHoveredComponent(name);
    onHover();
  };

  const { ref: productsRef, hoverHandler: productsHoverHandler } =
    useDetectClose(false);
  const { ref: supportRef, hoverHandler: supportHoverHandler } =
    useDetectClose(false);
  const { ref: resourcesRef, hoverHandler: resourcesHoverHandler } =
    useDetectClose(false);
  const { ref: learnRef, hoverHandler: learnHoverHandler } =
    useDetectClose(false);
  const { ref: aboutRef, hoverHandler: aboutHoverHandler } =
    useDetectClose(false);

  const menuRefs = [productsRef, supportRef, resourcesRef, learnRef, aboutRef];
  const hoverHandlers = [
    productsHoverHandler,
    supportHoverHandler,
    resourcesHoverHandler,
    learnHoverHandler,
    aboutHoverHandler,
  ];

  const menuItems = [
    {
      title: "Products",
      description: " ",
      onHover: productsHoverHandler,
      items: [
        {
          subtitle: "OpenFrame Refactor",
          onClick: () => {
            navigate("/products");
          },
          description:
            "OpenFrame Refactor is just the right tool for data management and migration from legacy to modern programming sources.",
          subItems: [
            {
              title: "OF Analyzer",
              description:
                "OF Analyzer analyzed Mainframe legacy resources in order to determine system or application architecture.",
            },
            {
              title: "OF Dataset Manager",
              description:
                "OF Dataset Manager manages and converts datasets based on the information analyzed by OF Analyzer.",
            },
            {
              title: "OF Migrator",
              description:
                "OF Migrator provides migration services for modernization of Mainframe applications.",
            },
          ],
        },
      ],
    },
    {
      title: "Resources",
      description: " ",
      onHover: resourcesHoverHandler,
      items: [
        {
          subtitle: "Documentation",
          onClick: () => {
            window.open("https://docs.tmaxsoft-openframe.com/", "_blank");
          },
          description:
            "See information for your project. The documentation is constantly being updated. We hope this information is useful to you.",
          subItems: [
            {
              title: "Getting Started",
              description:
                "Quick start for users to launch OpenFrame Refactor. Should you require any support, we stand by for you.",
            },
            {
              title: "Openframe Refactor",
              description:
                "Introduction to asset registration, project creation and analysis functions. OF Migration converts Cobol to Spring-based Java code sources. We provide the ability to migrate from legacy datasets to PostgreSQL and manage the data.",
            },
          ],
        },
      ],
    },
    {
      title: "Support",
      description: " ",
      onHover: supportHoverHandler,
      items: [
        {
          subtitle: "Support Plan",
          icon: (isHovered: boolean) => (
            <RecieptLongIcon color={isHovered ? "#1c293e" : "#7a828e"} />
          ),
          onClick: () => {
            navigate("/support_plan");
          },
          description:
            "Observe necessary plans for your project. We can also consult on business-scale mainframe modernization projects. Get in touch with us.",
          subItems: [
            {
              title: "Basic Support",
              description:
                "We provide technical documentations and learning contents basically.",
            },
            {
              title: "Advanced Support",
              description:
                "In addition to Basic Support, you'll be backed by experienced engineers.",
            },
          ],
        },
      ],
    },
    {
      title: "Learn",
      description: " ",
      onHover: learnHoverHandler,
      items: [
        {
          subtitle: "Training & Certification",
          icon: (isHovered: boolean) => (
            <HistoryEduIcon color={isHovered ? "#1c293e" : "#7a828e"} />
          ),
          onClick: () => {
            window.open("https://learn.tmaxsoft.com/");
          },
          description:
            "We've created an online training course for you, which includes a hands-on course and certification exam. Become an expert through our curriculum.",
          subItems: [
            {
              title: "Beginner Course",
              description:
                "Understand the concepts of modernization Mainframe with OpenFrame.",
            },
            {
              title: "Intermediate Course",
              description:
                "Understand how to replatform Mainframe to OpenFrame. Ideal for engineers working on OpenFrame replatform projects. ",
            },

            {
              title: "FAQ",
              description: "An forum for chatting about anything you want to.",
              onClick: () => {
                window.open(
                  "https://www.tmaxsoft.com/en/developer/developerFaq/list",
                );
              },
            },
          ],
        },
      ],
    },
    {
      title: "About",
      description: " ",
      onHover: aboutHoverHandler,
      items: [
        {
          subtitle: "About Us",
          icon: (isHovered: boolean) => (
            <BusinessIcon color={isHovered ? "#1c293e" : "#7a828e"} />
          ),
          onClick: () => {
            window.open("https://www.tmaxsoft.com/en/main/");
          },
          description:
            "TmaxSoft was founded on the principle that there is always a better way. Nothing will stop us from creating better solutions that improve your business in ways that other companies can’t.",
          subItems: [
            {
              title: "Report",
              description:
                "TmaxSoft named a Mainframe Modernization Software Leader for the second year in a row.",
            },
            {
              title: "Partners",
              description:
                "Each of our Legacy Mainframe modernization partners share our commitment to innovation, flexibility and agility. ",
            },

            {
              title: "Other Products",
              description:
                "TmaxSoft’s growing family of products give you the freedom you’ve always wanted, but until now could never find.",
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <div className={styles.Navbar}>
        <div className={styles.nav_container}>
          <img
            src={RefactorLogo}
            alt="OpenFrame"
            className={styles.logo}
            onClick={() => navigate("/")}
          />

          <div className={styles.menu_container}>
            {menuItems.map((item, idx) => (
              <div
                className={styles.menu}
                key={idx}
                ref={menuRefs[idx]}
                onMouseEnter={() =>
                  onChangeHover(item.title, hoverHandlers[idx])
                }
                onMouseLeave={() => onChangeHover("", hoverHandlers[idx])}
              >
                <div className={styles.container}>
                  <button
                    className={styles.button}
                    onClick={() => item.items[0]?.onClick()}
                  >
                    {item.title}
                  </button>

                  <div className={styles.popOver}>
                    {item.title === hoveredComponent && (
                      <MenuPopover
                        items={item.items}
                        ref={menuRefs[idx]}
                        hoverHandler={() => hoverHandlers[idx]()}
                        onChangeHover={onChangeHover}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.right_container}>
          <div className={styles.icon_container}></div>

          <div className={styles.button_container}>
            <Button
              title="Get Started"
              onClick={() => {
                window.open("http://console.tmaxsoft-openframe.com/");
              }}
            />
            <Button
              title="Contact Us"
              onClick={() => {
                if (location.pathname === "/contact_us") {
                  window.location.reload();
                }

                navigate("/contact_us");
              }}
              backgroundColor="#ffffff"
              textColor="#7244FB"
              otherStyle={{ border: "1px solid #7244FB" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
