import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//  libraries
import { CookiesProvider } from "react-cookie";
import { useEffect, useState } from "react";

// utils
import MoveToTop from "./utils/MoveToTop";
import { getCookie } from "./utils/cookie";
import RouteChangeTracker from "./utils/RouteChangeTracker";

// components
import Navbar from "src/components/organisms/Navbar/Navbar";
import Sitemap from "./components/organisms/Sitemap/Sitemap";
import CookieToast from "./components/molecules/CookieToast/CookieToast";
import Loading from "./components/atoms/Loading/Loading";

const Main = lazy(() =>
  import("src/pages/Main/Main").then(({ default: Main }) => ({
    default: Main,
  })),
);
const SupportPlan = lazy(() =>
  import("src/pages/SupportPlan/SupportPlan").then(
    ({ default: SupportPlan }) => ({
      default: SupportPlan,
    }),
  ),
);
const ContactUs = lazy(() =>
  import("src/pages/ContactUs/ContactUs").then(({ default: ContactUs }) => ({
    default: ContactUs,
  })),
);
const TermsAndConditions = lazy(() =>
  import("src/pages/TermsAndConditions/TermsAndConditions").then(
    ({ default: TermsAndConditions }) => ({ default: TermsAndConditions }),
  ),
);

const PrivacyPolicy = lazy(() =>
  import("src/pages/Privacy/PrivacyPolicy").then(
    ({ default: PrivacyPolicy }) => ({ default: PrivacyPolicy }),
  ),
);

const Products = lazy(() =>
  import("src/pages/Products/Products").then(({ default: Products }) => ({
    default: Products,
  })),
);
const Cookies = lazy(() =>
  import("src/pages/Cookies/Cookies").then(({ default: Cookies }) => ({
    default: Cookies,
  })),
);

function App() {
  const [isCookieToastOpen, setIsCookieToastOpen] = useState(false);

  const closeCookieToast = () => {
    setIsCookieToastOpen(false);
  };

  useEffect(() => {
    const isCookieSaved = getCookie("cookieChoices");
    setIsCookieToastOpen(!isCookieSaved);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <CookiesProvider>
            {isCookieToastOpen && (
              <CookieToast closeCookieToast={closeCookieToast} />
            )}
            <Navbar />
            <MoveToTop />
            <RouteChangeTracker />

            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/products" element={<Products />} />
              <Route path="/support_plan" element={<SupportPlan />} />
              <Route path="/contact_us" element={<ContactUs />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/legal/cookies" element={<Cookies />} />

              <Route
                path="/termsandconditions"
                element={<TermsAndConditions />}
              />
            </Routes>
            <Sitemap />
          </CookiesProvider>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
