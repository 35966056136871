import styles from "./CookieManageForm.module.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChevronRightIcon } from "src/assets/icon/chevronRightIcon.svg";
import Switch from "src/components/atoms/Switch";
import BasicButton from "src/components/atoms/BasicButton/BasicButton";

type Descriptions = {
  [key: string]: string;
};

type Choices = {
  Essential: boolean;
  Performance: boolean;
  Functional: boolean;
  Advertising: boolean;
};

type Props = {
  toggleForm: () => void;
  onSaveCookie: (choices: Choices) => void;
};

const cookieDescriptions: Descriptions = {
  Essential:
    "Essential cookies are necessary to provide our site and services and cannot be deactivated. They are usually set in response to your actions on the site, such as setting your privacy preferences, signing in, or filling in forms.",
  Performance:
    "Performance cookies provide anonymous statistics about how customers navigate our site so we can improve site experience and performance. Approved third parties may perform analytics on our behalf, but they cannot use the data for their own purposes.",
  Functional:
    "Functional cookies help us provide useful site features, remember your preferences, and display relevant content. Approved third parties may set these cookies to provide certain site features. If you do not allow these cookies, then some or all of these services may not function properly.",
  Advertising:
    "Advertising cookies may be set through our site by us or our advertising partners and help us deliver relevant marketing content. If  you do not allow these cookies, you will experience less relevant advertising.",
};

export default function CookieManageForm({ toggleForm, onSaveCookie }: Props) {
  const navigate = useNavigate();
  const { register, getValues } = useForm();

  const onClickSave = () => {
    const _formValue = getValues();
    const formValue = {
      ..._formValue,
      Essential: true,
    };
    onSaveCookie(formValue as Choices);
  };

  const itemWrapper = (name: string) => {
    return (
      <div className={styles.item_wrapper}>
        <h3>{name}</h3>
        <div className={styles.content}>
          <span>{cookieDescriptions[name as keyof Descriptions]}</span>
          {name !== "Essential" && <Switch id={name} register={register} />}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.CookieManageForm}>
      <div className={styles.header}>
        <ChevronRightIcon className={styles.icon} onClick={toggleForm} />
        <span>Manage Your Cookie Preferences</span>
      </div>
      <div className={styles.item_container}>
        {Object.keys(cookieDescriptions).map((name) => {
          return <div key={name}>{itemWrapper(name)}</div>;
        })}
        <div className={styles.info}>
          Blocking some types of cookies may impact your experience of our
          sites. Your may review and change your choices at any time by clicking
          Cookie preferences in the footer of this site. We and selected
          third-parties use cookies or similar technologies as specified in the
          <span
            className={styles.info_button}
            onClick={() => navigate("/legal/cookies")}
          >
            TmaxSoft Cookie Notice.
          </span>
        </div>
      </div>

      <div className={styles.button_container}>
        <BasicButton
          content={<span>Cancel</span>}
          level="secondary"
          type="outline"
          onClick={toggleForm}
        />

        <BasicButton
          content={<span>Save Preferences</span>}
          onClick={onClickSave}
        />
      </div>
    </div>
  );
}
