import styles from "./MenuPopover.module.scss";

// Hooks
import { forwardRef } from "react";

// Components
import SinglePagePopover from "../SinglePagePopover/SinglePagePopover";
import MultiPagePopover from "../MultiPagePopover/MultiPagePopover";

type SubProps = {
  subtitle: string;
  icon?: (isHovered: boolean) => JSX.Element;
  onClick?: () => void;
  description?: string;
  subItems?: {
    title: string;
    onClick?: () => void;
    description?: string;
  }[];
};

type Props = {
  items: SubProps[];
  hoverHandler: () => void;
  onChangeHover: Function;
};

const MenuPopover = forwardRef(
  (
    { items, hoverHandler, onChangeHover }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const SinglePageMenu = ["OpenFrame Refactor", "Support Plan", "Documentation", "Training & Certification", "About Us"];

    return (
      <div className={styles.MenuPopover}>
        <div className={styles.backdrop} />
        <div
          className={styles.container}
          ref={ref}
          onMouseLeave={() => onChangeHover("", () => hoverHandler)}
        >
          {SinglePageMenu.includes(items[0].subtitle) ? (
            <SinglePagePopover items={items} />
          ) : (
            <MultiPagePopover items={items} />
          )}
        </div>
      </div>
    );
  }
);

export default MenuPopover;
