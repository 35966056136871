import styles from "./Loading.module.scss";
import spinnerGif from "src/assets/gif/loading_type01.gif";

export default function Loading() {
  return (
    <div className={styles.container}>
      <img src={spinnerGif} alt="spinner" />
    </div>
  );
}
