// CSS
import styles from "./MultiPagePopover.module.scss";

// Assets
import { ReactComponent as ArrowRightIcon } from "src/assets/icon/arrowRightIcon.svg";

type ItemProp = {
  subtitle: string;
  icon?: (isHovered: boolean) => JSX.Element;
  onClick?: () => void;
  description?: string;
  subItems?: {
    title: string;
    description?: string;
    onClick?: () => void;
  }[];
};

type Prop = {
  items: ItemProp[];
};

export default function MultiPagePopover({ items }: Prop) {
  return (
    <div className={styles.MultiPagePopover}>
      <div className={styles.container}>
        {items.map((item) => (
          <div key={item.subtitle} className={styles.wrapper}>
            {item.icon && <div className={styles.icon}> {item.icon(true)}</div>}
            <div className={styles.content_wrapper}>
              <div className={styles.title_wrapper} onClick={item.onClick}>
                <div className={styles.title}>{item.subtitle}</div>
                <div className={styles.arrow}>
                  <ArrowRightIcon />
                </div>
              </div>

              <div className={styles.description}>{item.description}</div>

              <div className={styles.subItem_container}>
                {item?.subItems?.map((subItem) => (
                  <div key={subItem.title} className={styles.subItem}>
                    <div className={styles.subtitle} onClick={subItem?.onClick}>
                      {subItem.title}
                      <div className={styles.icon}>
                        <ArrowRightIcon />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
