// CSS
import styles from "./SinglePagePopover.module.scss";

// Assets
import AppGrayIcon from "src/assets/appGrayIcon";
import RecieptLongIcon from "src/assets/reciept_long";
import UserManualIcon from "src/assets/library_books";
import HistoryEduIcon from "src/assets/history_edu";
import BusinessIcon from "src/assets/business";
import { ReactComponent as ArrowCardForwardIcon } from "src/assets/icon/arrowCardForwardIcon.svg";
import { ReactComponent as ArrowRightIcon } from "src/assets/icon/arrowRightIcon.svg";

// Components
import TextArrowButton from "src/components/atoms/TextArrowButton/TextArrowButton";

type ItemProp = {
  subtitle: string;
  icon?: (isHovered: boolean) => JSX.Element;
  onClick?: () => void;
  description?: string;
  subItems?: {
    title: string;
    description?: string;
    onClick?: () => void;
  }[];
};

type Prop = {
  items: ItemProp[];
};

export default function SinglePagePopover({ items }: Prop) {
  return (
    <div className={styles.SinglePagePopover}>
      <div className={styles.iconBox}>
        {items[0].subtitle === "OpenFrame Refactor" ? (
          <AppGrayIcon />
        ) : items[0].subtitle === "Support Plan" ? (
          <RecieptLongIcon />
        ) : items[0].subtitle === "Documentation" ? (
          <UserManualIcon />
        ) : items[0].subtitle === "Training & Certification" ? (
          <HistoryEduIcon />
        ) : (
          <BusinessIcon />
        )}
      </div>

      <div className={styles.content}>
        {items.map((item) => (
          <div key={item.subtitle}>
            <div className={styles.title_wrapper}>
              <div className={styles.title} onClick={item.onClick}>
                {item.subtitle}

                <div className={styles.arrow}>
                  <ArrowCardForwardIcon />
                </div>
              </div>

              <div className={styles.description}>{item.description}</div>
            </div>
            <hr />

            <div
              className={`${styles.item_container} ${
                item.subItems && item.subItems.length === 3
                  ? styles.three_column
                  : styles.two_column
              }`}
            >
              {item.subItems?.map((el) => (
                <div
                  key={el.title}
                  className={`${styles.item} ${
                    el.onClick ? styles.clickable : undefined
                  }`}
                  onClick={el.onClick || undefined}
                >
                  <div className={styles.subtitle_wrapper}>
                    <div className={styles.subtitle}>{el.title}</div>

                    {el.onClick && (
                      <div className={styles.arrow}>
                        <ArrowRightIcon />
                      </div>
                    )}
                  </div>
                  <div className={styles.description}>{el.description}</div>
                </div>
              ))}
            </div>

            <div className={styles.page_button}>
              <TextArrowButton text="View details" onClick={item.onClick} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
