const AppGrayIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 2.5C18.3925 2.5 17.9 2.99249 17.9 3.6C17.9 4.20751 18.3925 4.7 19 4.7H20C21.6017 4.7 22.9 5.99837 22.9 7.6L22 7.6C21.588 7.6 21.3528 8.07038 21.6 8.4L23.6 11.0667C23.8 11.3333 24.2 11.3333 24.4 11.0667L26.4 8.4C26.6472 8.07038 26.412 7.6 26 7.6L25.1 7.6C25.1 4.78335 22.8167 2.5 20 2.5H19Z"
      fill="#7A828E"
    />
    <circle cx="10" cy="10" r="8" fill="#1C293E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.55403 13.0774C8.24161 13.3898 7.73508 13.3898 7.42266 13.0774L5.62976 11.2845C4.92682 10.5815 4.92682 9.44182 5.62976 8.73887L7.44201 6.92663C7.75443 6.61421 8.26096 6.61421 8.57338 6.92663C8.8858 7.23905 8.8858 7.74558 8.57338 8.058L6.76113 9.87024C6.68303 9.94835 6.68303 10.075 6.76114 10.1531L8.55403 11.946C8.86645 12.2584 8.86645 12.7649 8.55403 13.0774Z"
      fill="#ECEEF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3578 6.28446C14.753 6.48205 14.9131 6.96259 14.7155 7.35778L11.7155 13.3578C11.518 13.753 11.0374 13.9131 10.6422 13.7155C10.2471 13.518 10.0869 13.0374 10.2845 12.6422L13.2845 6.64223C13.4821 6.24705 13.9626 6.08687 14.3578 6.28446Z"
      fill="#B9BDC3"
    />
    <path
      d="M20.5 11.2887C20.8094 11.11 21.1906 11.11 21.5 11.2887L29.1602 15.7113C29.4696 15.89 29.6602 16.2201 29.6602 16.5773V25.4226C29.6602 25.7799 29.4696 26.11 29.1602 26.2887L21.5 30.7113C21.1906 30.8899 20.8094 30.8899 20.5 30.7113L12.8397 26.2887C12.5303 26.11 12.3397 25.7799 12.3397 25.4226V16.5773C12.3397 16.2201 12.5303 15.89 12.8397 15.7113L20.5 11.2887Z"
      fill="#B9BDC3"
    />
    <path
      d="M20.5 14.8181C20.8094 14.6395 21.1906 14.6395 21.5 14.8181L26.1037 17.476C26.4131 17.6547 26.6037 17.9848 26.6037 18.3421V23.6579C26.6037 24.0152 26.4131 24.3453 26.1037 24.524L21.5 27.1819C21.1906 27.3605 20.8094 27.3605 20.5 27.1819L15.8963 24.524C15.5869 24.3453 15.3963 24.0152 15.3963 23.6579V18.3421C15.3963 17.9848 15.5869 17.6547 15.8963 17.476L20.5 14.8181Z"
      fill="#7A828E"
    />
    <path
      d="M21 18.0588L23.5471 19.5294V22.4706L21 23.9412L18.4528 22.4706V19.5294L21 18.0588Z"
      fill="#1C293E"
    />
  </svg>
);

export default AppGrayIcon;
