import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function MoveToTop() {
  const pathName = useLocation();

  useEffect(() => {
    moveToTop();
  }, [pathName]);

  const moveToTop = () => {
    // top:0 맨 위, smooth 는 부드럽게 이동
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return null;
}
