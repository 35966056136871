import styles from "./BasicButton.module.scss";
import { MouseEventHandler, ReactNode } from "react";

type Props = {
  content: ReactNode | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  level?: "primary" | "secondary";
  type?: "filled" | "outline";
};

export default function BasicButton({
  content,
  onClick = undefined,
  disabled = false,
  level = "primary",
  type = "filled",
}: Props) {
  return (
    <div className={styles.BasicButton}>
      <button
        onClick={!disabled ? onClick : undefined}
        className={`${styles[level]} ${styles[type]} ${
          !disabled ? styles.clickable : styles.disabled
        }`}
      >
        {content}
      </button>
    </div>
  );
}
