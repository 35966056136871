import { useEffect, useState, useRef } from "react";

const useDetectClose = (initialState: boolean) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState);
  const ref = useRef(null) as React.RefObject<HTMLDivElement>;

  const hoverHandler: Function = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const onHover = (event: React.BaseSyntheticEvent | MouseEvent) => {
      if (ref.current !== null && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("mouseenter", onHover);
    window.addEventListener("mouseleave", onHover);

    return () => {
      window.removeEventListener("mouseenter", onHover);
      window.removeEventListener("mouseleave", onHover);
    };
  }, [isOpen]);

  return { ref, hoverHandler };
};

export default useDetectClose;
