import { ChangeEventHandler } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

import s from "./switch.module.scss";

interface SwitchProps {
  id: string;
  description?: [string, string];
  register: UseFormRegister<FieldValues>;
  defaultValue?: boolean;
  onChange?: ChangeEventHandler;
}

export const Switch = ({
  description,
  register,
  id,
  defaultValue = true,
  onChange,
}: SwitchProps) => {
  return (
    <div className={s.swtichContainer}>
      <input
        className={s.switchCheckBox}
        type="checkbox"
        defaultChecked={defaultValue}
        id={id}
        {...register(id, { onChange })}
      />
      <label className={s.switchLabel} htmlFor={id} />
      {description && (
        <p className={`${s.switchDescription} ${s.checked}`}>
          {description[0]}
        </p>
      )}
      {description && (
        <p className={`${s.switchDescription} ${s.notChecked}`}>
          {description[1]}
        </p>
      )}
    </div>
  );
};

export default Switch;
